/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import initializeCustobar from '@shared/utils/custobarTracking'

export { wrapRootElement } from '@shared/gatsby'

export const onInitialClientRender = () => initializeCustobar()
