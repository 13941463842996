import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'

/**
 * Used by gatsby-browser.js and gatsby-ssr.js to provide an emotion theme context to all components
 */
export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)
