// prettier-ignore

/**
 * Initialize the Custobar tracking script
 * https://slpgd.custobar.com/settings/tracking/
 */
const initializeCustobar = () => {
  ;(function(c,u,s,t,o,b,a,r) {var e;c[o]=[];c[b]=a;e=u.createElement(s);
    r=u.getElementsByTagName(s)[0];e.async=1;e.src=t;
    r.parentNode.insertBefore(e,r);})(window,document,'script',
    'https://api.custobar.com/js/v1/custobar.js',
    'cstbr','cstbrConfig',{"_companyToken":"APISLPGDP2W4ZRPTOW3DCC6DOZHE73N4A6RFSK3A"});
}

export default initializeCustobar
