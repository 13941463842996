import {
  white,
  black,
  gold,
  silver,
  copper,
  grayLight,
  grayDark,
} from './colors'
import { css } from '@emotion/core'
import { rem } from '../styles/mixins/sizing'

const BREAKPOINTS = {
  mobileLarge: rem(667),
  tablet: rem(786),
}

export const MEDIA_QUERIES = {
  '<mobileLarge': `@media (max-width: ${BREAKPOINTS.mobileLarge})`,
  '<tablet': `@media (max-width: ${BREAKPOINTS.mobileLarge})`,
}

const FONT_STACKS = {
  HEADING:
    '"Napue Sans Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  BODY:
    'Domaine, Constantia, "Lucida Bright", Lucidabright, "Lucida Serif", Lucida, "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif',
}

const theme = {
  colors: {
    background: white,
    text: black,
    alternateText: grayDark,
    primary: gold,
    secondary: silver,
    tertiary: copper,
    highlight: grayLight,
  },

  blocks: {
    light: {
      background: white,
      foreground: black,
    },
    dark: {
      background: black,
      foreground: white,
    },
  },

  fonts: {
    heading: FONT_STACKS.HEADING,
    body: FONT_STACKS.BODY,
  },

  type: {
    turboHeadline: {
      default: css`
        font-family: ${FONT_STACKS.HEADING};
        font-weight: 800;
        text-transform: uppercase;
        font-size: ${rem(108)};
        line-height: ${rem(120)};
      `,
      '<mobileLarge': css`
        font-size: ${rem(40)};
        line-height: ${rem(44)};
      `,
    },

    headline: {
      default: css`
        font-family: ${FONT_STACKS.HEADING};
        font-weight: 800;
        text-transform: uppercase;
        font-size: ${rem(44)};
        line-height: ${rem(48)};
      `,
      '<mobileLarge': css`
        font-size: ${rem(40)};
        line-height: ${rem(44)};
      `,
    },
    ingress: {
      default: css`
        font-family: ${FONT_STACKS.HEADING};
        font-weight: 800;
        text-transform: uppercase;
        font-size: ${rem(20)};
        line-height: ${rem(24)};
      `,
    },
    label: {
      default: css`
        font-family: ${FONT_STACKS.HEADING};
        font-weight: 800;
        text-transform: uppercase;
        font-size: ${rem(20)};
        line-height: ${rem(24)};
      `,
    },
    body: {
      default: css`
        font-family: ${FONT_STACKS.BODY};
        font-size: ${rem(16)};
        line-height: ${rem(24)};
      `,
    },
  },

  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: rem(512),
  },

  gutters: {
    xs: rem(10),
    sm: rem(18),
    md: rem(42),
    lg: rem(84),
    xl: rem(108),
  },

  responsive: {
    sm: '35em',
    md: '50em',
    lg: '70em',
  },

  borders: {
    default: css`
      border: 4px solid ${grayLight};
    `,
    alternate: css`
      border: 4px solid ${gold};
    `,
    width: '4px',
    color: grayLight,
    highlightColor: gold,
  },

  transition: {
    fast: '.2s ease',
  },
}

export default theme
