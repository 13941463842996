import { css } from '@emotion/core'

const BASE_FONT_SIZE = 16

export const rem = (size, context = BASE_FONT_SIZE) => `${size / context}rem`

export const fullWidth = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`
